import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'

const Styckepris = () => (
  <Layout>
    <Seo title='Styckepriser' />
    <PageHeader text='Styckepriser' />
    <div className='container'>
      <div className='row'>

        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>

          <h3>Personbil</h3>
          <table className='table-borderless table-striped table-hover'>

            <tbody>
              <tr>
                {/* <td className='pl-2'> <Link to='/boka-utbildning'>Körlektion á 50 min</Link></td> */}
                <td className='pl-2'> Körlektion á 50 min</td>
                {/* <td className='float-right pr-2'><Link to='/e-handel/productDetails/5625'>Klicka för aktuellt pris</Link></td> */}
                <td className='float-right pr-2'>900 kr <spa style={{ color: 'rgb(172, 3, 11)' }}>*</spa></td>
              </tr>
              <tr>

                <td className='pl-2'> <Link to='/info/lan-av-trafikskolans-fordon-pa-prov'>Bil vid uppkörning</Link></td>
                <td className='float-right pr-2'>1800 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> Individuell teori á 50 min</td>
                <td className='float-right pr-2'>450 kr</td>
              </tr>
            </tbody>
          </table>
            <h6>*avser ordinarie pris.</h6>   
            <h6>*Vid köp av våra paketerbjudanden så tillkommer rabatt på ordinarie priset</h6>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <h3>Kurser</h3>
          <table className='table-borderless table-striped table-hover'>

            <tbody>
              <tr>
                <td className='pl-2'><Link to='/boka-utbildning'>Handledarutbildning</Link></td>
                <td className='float-right pr-2'>299 kr</td>
                {/* <td className='float-right pr-2'><Link to='/e-handel/productDetails/5470'>Klicka för aktuellt pris</Link></td> */}

              </tr>
              <tr>
                <td className='pl-2'><Link to='/boka-utbildning'>Riskutbildning del 1</Link></td>
                <td className='float-right pr-2'>700 kr</td>

              </tr>
              <tr>
                {/* <td className='pl-2'><Link to='/e-handel/productDetails/5474'>Riskutbildning del 2</Link></td> */}
                <td className='pl-2'><Link to='/utbildningar/riskutbildningar'>Riskutbildning del 2</Link></td>
                <td className='float-right pr-2'>2200 kr</td>

              </tr>
            </tbody>
          </table>

        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <h3>Material</h3>
          <table className='table-borderless table-striped table-hover'>
            <tbody>
              {/* <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5635'>Körkort nu: Allt - för bil</Link></td>
                <td className='float-right pr-2'>790 kr</td> 
              </tr> */}
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5635'>Körkortsboken.se 1 månad</Link></td>
                <td className='float-right pr-2'>349 kr</td> 
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5635'>Körkortsboken.se 3 månader</Link></td>
                <td className='float-right pr-2'>798 kr</td> 
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5635'>Körkortsboken.se 6 månader</Link></td>
                <td className='float-right pr-2'>1150 kr</td> 
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5635'>Körkortsboken.se 12 månader</Link></td>
                <td className='float-right pr-2'>1400 kr</td> 
              </tr>
              {/* <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5645'>Körkort nu - frågor</Link></td>
                <td className='float-right pr-2'>200 kr</td>
              </tr> */}
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5636'>Körkortsboken</Link></td>
                <td className='float-right pr-2'>315 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> <Link to='/e-handel/productDetails/5642'>Studiehäfte körkortsboken</Link></td>
                <td className='float-right pr-2'>275 kr</td>
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5640'>Körhäfte körkortsboken</Link></td>
                <td className='float-right pr-2'>125 kr</td>
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5641'>Teorihäfte körkortsboken</Link></td>
                <td className='float-right pr-2'>125 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> <Link to='/e-handel/productDetails/5638'>Lättlästa Körkortsboken</Link></td>
                <td className='float-right pr-2'>295 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> <Link to='/e-handel/productDetails/5639'>Körkortsboken engelska</Link></td>
                <td className='float-right pr-2'>500 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> <Link to='/e-handel/productDetails/5644'>Trafikens Grunder - Köra Bil</Link></td>
                <td className='float-right pr-2'>275 kr</td>
              </tr>
              <tr>
                <td className='pl-2'> <Link to='/e-handel/productDetails/5643'>Trafikens Grunder</Link></td>
                <td className='float-right pr-2'>315 kr</td>
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5637'>Handledarboken</Link></td>
                <td className='float-right pr-2'>100 kr</td>
              </tr>
              <tr>
                <td className='pl-2'><Link to='/e-handel/productDetails/5646'>Trafikkunskap Seniorföraren</Link></td>
                <td className='float-right pr-2'>200 kr</td>
              </tr>

            </tbody>
          </table>

        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
          <h3>Övrigt</h3>
          <table className='table-borderless table-striped table-hover'>
            <tbody>
              <tr>
                <td className='pl-2'>Övningsskylt på tak</td>
                <td className='float-right pr-2'>400 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Övningsskylt magnet</td>
                <td className='float-right pr-2'>60 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Övningsskylt sugpropp</td>
                <td className='float-right pr-2'>75 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Backspegel stor</td>
                <td className='float-right pr-2'>100 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Backspegel liten</td>
                <td className='float-right pr-2'>75 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Backspegel yttre</td>
                <td className='float-right pr-2'>75 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Synundersökning</td>
                <td className='float-right pr-2'>150 kr</td>
              </tr>
              <tr>
                <td className='pl-2'>Fraktkostnad E-handeln</td>
                <td className='float-right pr-2'>99 kr</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default Styckepris
